import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { onServerPrefetch } from 'vue'
import { useAppStore } from '@/store/app-store'
import ErrorPage from './ErrorPage.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'NotFoundPage',
  setup(__props) {

const appStore = useAppStore()

onServerPrefetch(() => {
    appStore.setPageStatus(404)
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ErrorPage, { type: 404 }))
}
}

})